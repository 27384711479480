<template>
  <main class="kb-main" id="kb-hottip-search">
    <HotTipHeader />
    <div class="main-content main-component hottip-content">
      <lxp-main-header :show-custom-title="true" :title="pageTitle" />
      <section v-if="!isLoading" :class="{'mt-6' : !isMobile}">
        <div class="hottip-board">
          <HotTipBoardPreview v-if="isMobile && showOptions.modal" :board-id="modalBoard.boardId" :custom-board-list="modalList">
            <template v-slot:subTitle>
              <div class="section-bottom mt-3">
                <button class="kb-btn kb-btn-wide kb-btn-outline kb-btn-xs" @click="clearHotTipMoadl">
                  <span class="text">나가기</span>
                </button>
              </div>
            </template>
          </HotTipBoardPreview>
          <template v-else>
            <HotTipBoardPreview board-id="hottip-my" :page-size="pageSize" :show-all-columns="showAllColunms">
              <template v-if="$route.query.type == 'my'" v-slot:subTitle>
                <template v-if="isMobile" >
                  <div class="section-bottom mt-3">
                    <button class="kb-btn kb-btn-wide kb-btn-outline kb-btn-xs" @click="getHotTipModalList('hottip-my')">
                      <span class="text">전체보기</span>
                    </button>
                  </div>
                </template>
                <span v-else class="title-sub kb-mouse-cursor" @click.stop="getHotTipModalList('hottip-my')">전체보기</span>
              </template>
            </HotTipBoardPreview>
            <HotTipBoardPreview v-if="showOptions.hq" board-id="hottip-hq" :page-size="pageSize" :show-all-columns="showAllColunms">
              <template v-if="$route.query.type == 'my'" v-slot:subTitle>
                <template v-if="isMobile" >
                  <div class="section-bottom mt-3">
                    <button class="kb-btn kb-btn-wide kb-btn-outline kb-btn-xs" @click="getHotTipModalList('hottip-hq')">
                      <span class="text">전체보기</span>
                    </button>
                  </div>
                </template>
                <span v-else class="title-sub kb-mouse-cursor" @click.stop="getHotTipModalList('hottip-hq')">전체보기</span>
              </template>
            </HotTipBoardPreview>
            <HotTipBoardPreview v-if="showOptions.biz" board-id="hottip-biz" :page-size="pageSize" :show-all-columns="showAllColunms">
              <template v-if="$route.query.type == 'my'" v-slot:subTitle>
                <template v-if="isMobile" >
                  <div class="section-bottom mt-3">
                    <button class="kb-btn kb-btn-wide kb-btn-outline kb-btn-xs" @click="getHotTipModalList('hottip-biz')">
                      <span class="text">전체보기</span>
                    </button>
                  </div>
                </template>
                <span v-else class="title-sub kb-mouse-cursor" @click.stop="getHotTipModalList('hottip-biz')">전체보기</span>
              </template>
            </HotTipBoardPreview>
          </template>
        </div>
        <div v-if="showOptions.hotTube" class="section-area board">
          <div class="section-header pb-3">
            <h3 class="title">Hot Tube</h3>
            <span v-if="!isMobile" class="title-sub kb-mouse-cursor" @click="moveToHotTubeList">전체보기</span>
          </div>
          <LoadingDiv v-if="isLoading" />
          <template v-else-if="hotTubeList.length > 0">
            <div class="section-body">
              <HrdCloudSwiper :items="hotTubeList" :session="session" />
            </div>
            <div v-if="isMobile" class="section-bottom">
              <button class="btn btn-wide btn-outline btn-xs" @click="moveToHotTubeList"><span class="text">전체보기</span></button>
            </div>
          </template>
          <div v-else class="section-body">
            <div class="board-preview-list">
              <p class="text-center text-muted pb-6">콘텐츠가 없습니다.</p>
            </div>
          </div>
        </div>
      </section>
      <div v-if="isMobile" class="visitor-info">
        <span class="text text-center" v-html="hotTipManagerInfo"></span>
      </div>
    </div>
  </main>

<!-- 나의 핫팁 전체보기 모달 -->
  <div v-if="!isMobile" class="popup-container" :class="{'is-active': showOptions.modal}">
    <div v-if="modalBoard" class="popup">
      <div class="popup-inner">
        <header class="popup-header">
          <h3 class="title-dis">나의 Hot Tip</h3>
        </header>
        <div class="hottip-content">
          <div v-if="modalList.length > 0" class="hottip-board">
            <HotTipBoardPreview :board-id="modalBoard.boardId" :show-all-columns="true" :custom-board-list="modalList">
              <template v-slot:subTitle>
                <span></span>
              </template>
            </HotTipBoardPreview>
          </div>
          <div v-else>
            <span class="text text-center">게시글이 없습니다.</span>
          </div>
        </div>
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="clearHotTipMoadl"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, onBeforeUnmount, onMounted, reactive, ref, watch} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import LxpMainHeader from "@/components/common/LxpMainHeader";
import HotTipBoardPreview from "@/components/prep/HotTipBoardPreview.vue";
import HotTipHeader from "@/components/prep/HotTipHeader.vue";
import HrdCloudSwiper from "@/components/hrdcloud/HrdCloudSwiper.vue";
import LoadingDiv from "@/components/common/LoadingDiv.vue";
import {getCheckItems, setParamsByQuery} from "@/assets/js/util";
import {MUT_TOGGLE_CONCENTRATION_MODE} from "@/store/modules/hrdcloud/hrdcloud";
import {getHrdCloudList} from "@/assets/js/modules/hrd/hrd-common";
import {
  hotTubeParams,
  hotTipManagerInfo,
  HotTipBoardBadgeCdDcd,
  getHotTipBoardMst, getHotTipBoardCateList
} from "@/assets/js/modules/learn/learn-hot-tip";
import {getPostList} from "@/assets/js/modules/board/board-common";

export default {
  name: 'HotTipSearch',
  components: {HotTipHeader, LoadingDiv, HrdCloudSwiper, LxpMainHeader, HotTipBoardPreview},
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const session = computed(() => store.state.auth.session);
    const isMobile = computed(() => store.state.auth.isMobile);
    const isLoading = ref(true);
    const pageTitle = ref(null);
    const search = reactive({
      word: null,
      boardCateSns: [],
    });
    const hotTubeList = ref([]);
    const showOptions = reactive({
      hotTube: false,
      hq: false,
      biz: false,
      modal: false,
    });
    const showAllColunms = ref(false);
    const pageSize = ref(10);

    const getHotTubeList = async () => {
      const res = await getHrdCloudList({...hotTubeParams, keyword: search.word, pageSize: 10});
      hotTubeList.value = getCheckItems(res);
      showOptions.hotTube = true;
    }

    const moveToHotTubeList = () => {
      router.push({name: 'HrdCloud', query: {...hotTubeParams, keyword: search.word}});
    }

    const modalBoard = ref(null);
    const modalBoardCateList = ref([]);
    const modalList = ref([]);
    const getHotTipModalList = (boardId) => {
      modalBoard.value = getHotTipBoardMst(boardId);
      modalBoardCateList.value = getHotTipBoardCateList(boardId);
      getPostList({boardMstSn: modalBoard.value.boardMstSn, rgtrId: session.value.lrnerId, stt: '01', pageNo: -1}, modalList, null, modalBoardCateList.value, () => {
        showOptions.modal = true;
      });
    }

    const clearHotTipMoadl = () => {
      showOptions.modal = false;
      modalBoard.value = null;
      modalBoardCateList.value = [];
      modalList.value = [];
    }

    const setPageType = () => {
      // 보여주기 옵션 false 초기화
      Object.keys(showOptions).map(k => {showOptions[k] = false});
      showAllColunms.value = false;

      // 리스트 기본 10개
      pageSize.value = isMobile.value ? 6 : 10;

      // 페이지 구분
      if(route.query.type === 'my'){
        pageTitle.value = '나의 Hot Tip';
        showOptions.hq = true;
        showOptions.biz = true;
      }else if(route.query.isLike === 'Y'){
        pageTitle.value = '내가 좋아요한 Hot Tip';
        showOptions.hq = true;
        showOptions.biz = true;
      }else if(route.query.postBadgeCdDcd === HotTipBoardBadgeCdDcd.BEST){
        pageTitle.value = '우수 Tip 모아보기';
        showOptions.biz = true;
      }else if(route.query.type){
        pageTitle.value = isMobile.value ? route.query.type : '[업무별 Hot Tip] ' + route.query.type;
        showOptions.hq = true;
      }else{
        pageTitle.value = 'Hot Tip 검색';
        showOptions.hq = true;
        showOptions.biz = true;
        showAllColunms.value = isMobile.value ? false : true;
        getHotTubeList();
      }

      setTimeout(() => {isLoading.value = false}, 300);
    }

    watch(() => route.query, () => {
      if(route.name === 'HotTipSearch'){
        isLoading.value = true;
        setParamsByQuery(route, search, false);
        setPageType();
      }
    })

    onMounted(() => {
      // 배경색 변경
      store.commit(`hrdcloud/${MUT_TOGGLE_CONCENTRATION_MODE}`, { 'mode' : 'fill'});
      setParamsByQuery(route, search, true);
      setPageType();
    })

    onBeforeUnmount(() => {
      // 배경색 변경
      store.commit(`hrdcloud/${MUT_TOGGLE_CONCENTRATION_MODE}`, { 'mode' : ''});
    })

    return {
      session,
      isMobile,
      pageTitle,
      isLoading,
      hotTubeList,
      showOptions,
      showAllColunms,
      pageSize,
      modalBoard,
      modalList,
      hotTipManagerInfo,
      moveToHotTubeList,
      getHotTipModalList,
      clearHotTipMoadl,
    }
  }
}
</script>